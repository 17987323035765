<template>
  <div class="profile-content-wrapper" v-if="!AUTH.isRequest">
    <!-- настройки -->
    <div class="content-block profile-settings">
      <div class="content-block__title">Настройки профиля</div>
      <div class="edit" :class="{text_yellow: isEdit}">
        <div v-if="!isEdit" @click="editOrSaveData">Редактировать</div>
        <div v-else @click="updateUserData">Сохранить изменения</div>
      </div>
      <div class="content-block__content">
        <div class="content-block__string mb15">
          <div class="s1">Ник</div>
          <label class="s2">
            <input ref="nick" type="text" v-model="user.login" :disabled="!isEdit"
                   placeholder="Придумайте себе ник">
          </label>
        </div>
        <div class="content-block__string mb15">
          <div class="s1">Язык</div>
          <div class="s2">
            <Multiselect
                :disabled="!isEdit"
                ref="lang"
                :options="user.lang.options"
                v-model="user.lang.value"
                track-by="placeholder"
                label="placeholder"
                placeholder="Выберите язык"
                :allow-empty="false"
                :selectLabel="null"
                :selectedLabel="null"
                :deselectLabel="null"
                open-direction="bottom"
            >
              <template slot="tag" slot-scope="{ option }">
                <span class="custom__tag">
                  <span>{{ option.placeholder }}</span>
                </span>
              </template>
              <span slot="noResult">Не найдено</span>
            </Multiselect>
          </div>
        </div>
        <div class="content-block__string mb15">
          <div class="s1">Часовой пояс</div>
          <div class="s2">
            <Multiselect
                :disabled="!isEdit"
                ref="timezone"
                :options="user.timezone.options"
                v-model="user.timezone.value"
                track-by="placeholder"
                label="placeholder"
                placeholder="Выберите часовой пояс"
                :allow-empty="false"
                :selectLabel="null"
                :selectedLabel="null"
                :deselectLabel="null"
                open-direction="bottom"
            >
              <template slot="tag" slot-scope="{ option }">
                <span class="custom__tag">
                  <span>{{ option.placeholder }}</span>
                </span>
              </template>
              <span slot="noResult">Не найдено</span>
            </Multiselect>
          </div>
        </div>
        <div class="divider"></div>
        <div class="content-block__string mb15">
          <div class="s1">Имя</div>
          <label class="s2">
            <input ref="firstName" type="text" v-model="user.firstName" :disabled="!isEdit">
          </label>
        </div>
        <div class="content-block__string mb15">
          <div class="s1">Фамилия</div>
          <label class="s2">
            <input ref="lastName" type="text" v-model="user.lastName" :disabled="!isEdit">
          </label>
        </div>
        <div class="content-block__string mb15">
          <div class="s1">Пол</div>
          <div class="s2">
            <Multiselect
                :disabled="!isEdit"
                ref="gender"
                :options="user.gender.options"
                v-model="user.gender.value"
                track-by="placeholder"
                label="placeholder"
                placeholder="Выберите пол"
                :searchable="false"
                :allow-empty="false"
                :selectLabel="null"
                :selectedLabel="null"
                :deselectLabel="null"
                open-direction="bottom"
            >
              <template slot="tag" slot-scope="{ option }">
                <span class="custom__tag">
                  <span>{{ option.placeholder }}</span>
                </span>
              </template>
            </Multiselect>
          </div>
        </div>
        <div class="content-block__string mb15">
          <div class="s1">День рождения</div>
          <div class="s2">
            <flat-pickr
                :disabled="!isEdit"
                v-model="user.birthday.value"
                :config="{
                  locale: user.birthday.locale,
                  maxDate: 'today',
                  dateFormat: 'd M Y',
                  position: 'below',
                  onChange: (date) => {
                    // locale can also be used
                    formatDate(date);
                  }
                }"
                placeholder="Укажите дату своего рождения"
            ></flat-pickr>
          </div>
        </div>
        <div class="content-block__string mb15">
          <div class="s1">Страна</div>
          <div class="s2">
            <Multiselect
                :disabled="!isEdit"
                ref="country"
                :options="user.country.options"
                v-model="user.country.value"
                track-by="placeholder"
                label="placeholder"
                placeholder="Выберите страну"
                :allow-empty="false"
                :selectLabel="null"
                :selectedLabel="null"
                :deselectLabel="null"
                open-direction="bottom"
            >
              <template slot="tag" slot-scope="{ option }">
                <span class="custom__tag">
                  <span>{{ option.placeholder }}</span>
                </span>
              </template>
              <span slot="noResult">Не найдено</span>
            </Multiselect>
          </div>
        </div>
        <div class="content-block__string mb15">
          <div class="s1">Город</div>
          <label class="s2">
            <input ref="city" type="text" v-model="user.city" placeholder="Ваш город" :disabled="!isEdit">
          </label>
        </div>
        <div class="divider"></div>
        <div class="content-block__string mb15">
          <div class="s1">Twitch</div>
          <label class="s2">
            <input ref="twitch" type="text" v-model="user.twitch" placeholder="Ссылка на ваш канал twitch" :disabled="!isEdit">
          </label>
        </div>
        <div class="content-block__string mb15">
          <div class="s1">Vk</div>
          <label class="s2">
            <input ref="vk" type="text" v-model="user.vk" placeholder="Ссылка на ваш профиль vk" :disabled="!isEdit">
          </label>
        </div>
        <div class="content-block__string mb15">
          <div class="s1">PSN ID</div>
          <label class="s2">
            <input ref="vk" type="text" v-model="user.psn_id" placeholder="Ваш PlayStation Network ID" :disabled="!isEdit">
          </label>
        </div>
      </div>
    </div>

    <!-- команды -->

    <!--    <div class="content-block user-teams">
          <div class="content-block__title">Команда</div>
          <div class="content-block__content">
            <div v-if="USER_MANAGED_TEAMS.length">
              <router-link v-for="(item, index) in USER_MANAGED_TEAMS.length" :key="item"
                           :to="{name: 'team.name', params: {id: USER_MANAGED_TEAMS[index].id}}">
                <div class="team content-block__string">
                  <div class="s1">
                    <div class="team-label">
                      <div class="logo">
                        <img :src="`${USER_MANAGED_TEAMS[index].logo}`" alt="">
                      </div>
                      <div class="team-label__text">
                        <div class="name">{{ USER_MANAGED_TEAMS[index].attributes.name }}</div>
                        <media :query="{maxWidth: 580}">
                          <div class="s2 team_game">Менеджер</div>
                        </media>
                      </div>
                    </div>
                  </div>
                  <media :query="{minWidth: 581}">
                    <div class="s2 team_game">Менеджер</div>
                  </media>
                </div>
                <div class="divider"></div>
              </router-link>
            </div>
            <div v-if="USER_TEAMS.length">
              <router-link v-for="(item, index) in USER_TEAMS.length" :key="item"
                           :to="{name: 'team.name', params: {id: USER_TEAMS[index].id}}">
                <div class="team content-block__string">
                  <div class="s1">
                    <div class="team-label">
                      <div class="logo">
                        <img :src="`${USER_TEAMS[index].logo}`" alt="">
                      </div>
                      <div class="team-label__text">
                        <div class="name">{{ USER_TEAMS[index].attributes.name }}</div>
                        <media :query="{maxWidth: 580}">
                          <div class="s2 team_game">Игрок</div>
                        </media>
                      </div>
                    </div>
                  </div>
                  <media :query="{minWidth: 581}">
                    <div class="s2 team_game">Игрок</div>
                  </media>
                </div>
                <div class="divider"></div>
              </router-link>
            </div>
            <div v-if="!allUserTeams.length">
              <p>Вы ещё не состоите ни в одной команде :(</p>
              <p>Создайте свою или присоединитесь к уже существующей.</p>
            </div>

            <template v-if="!AUTH.isRequest">
              <div v-if="USER_REQUESTED_TEAMS.length">
                <router-link v-for="(item, index) in USER_REQUESTED_TEAMS.length" :key="item"
                             :to="{name: 'team.name', params: {id: USER_REQUESTED_TEAMS[index].id}}">
                  <div class="team team__requested content-block__string">
                    <div class="s1">
                      <div class="team-label">
                        <div class="logo">
                          <img :src="USER_REQUESTED_TEAMS[index].logo" alt="">
                        </div>
                        <div class="team-label__text">
                          <div class="name">{{ USER_REQUESTED_TEAMS[index].attributes.name }}</div>
                          <media :query="{maxWidth: 580}">
                            <div class="s2 team_game">
                              {{ USER_REQUESTED_TEAMS[index].userRole === 'manager' ? 'Менеджер' : 'Игрок' }}
                            </div>
                          </media>
                        </div>
                      </div>
                    </div>
                    <media :query="{minWidth: 581}">
                      <div class="s2 team_game">{{
                          USER_REQUESTED_TEAMS[index].userRole === 'manager' ? 'Менеджер' : 'Игрок'
                        }}
                      </div>
                    </media>
                    <div class="s3">Ожидается подтверждение</div>
                  </div>
                  <div class="divider"></div>
                </router-link>
              </div>
            </template>
            <div v-if="!USER_TEAMS.length" class="team_add__wrapper">
              <router-link class="filled__grey team_add" to="/teams/create">Создать</router-link>
            </div>
          </div>
        </div>-->

  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import {usersMixin} from "@/otherJS/mixins/usersMixin";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { Russian } from "flatpickr/dist/l10n/ru.js"

export default {
  components: {
    Multiselect,
    flatPickr
  },
  name: "profileInfo",
  mixins: [usersMixin],
  data() {
    return {
      isEdit: false,
      counter: 0,
      user: {
        login: null,
        firstName: 'Ваше',
        lastName: 'Имя',
        city: 'Ваш город',
        country: {
          value: null,
          options: []
        },
        gender: {
          value: null,
          options: [
            {value: 'male', placeholder: 'мужской'},
            {value: 'female', placeholder: 'женский'},
          ]
        },
        lang: {
          value: null,
          options: []
        },
        timezone: {
          value: null,
          options: []
        },
        birthday: {
          locale: Russian,
          value: null,
          formatted_value: null
        },
        twitch: '',
        vk: '',
        psn_id: ''
      },
      allUserTeams: null
    }
  },
  computed: {
    ...mapGetters([
      'USER',
      'USER_TEAMS',
      'USER_REQUESTED_TEAMS',
      'USER_MANAGED_TEAMS',
      'AUTH',
      'COUNTRIES',
      'LANGUAGES',
      'TIMEZONES'
    ]),
    dateValue() {
      return this.user.birthday.value;
    },
    getCurDate() {
      return moment().toDate()
    }
  },
  methods: {
    ...mapActions([
      'GET_SINGLE_USER',
      'UPDATE_USER_DATA'
    ]),
    editOrSaveData() {
      this.isEdit = this.isEdit === false;
    },
    getBirthdayValue() {
      return this.USER.attributes.birthdayAt ? this.USER.attributes.birthdayAt : '';
    },
    setProfileData() {
      this.user.login = this.USER.attributes.login !== null ? this.USER.attributes.login : null
      this.user.firstName = this.USER.attributes.firstName !== null ? this.USER.attributes.firstName : null
      this.user.lastName = this.USER.attributes.lastName !== null ? this.USER.attributes.lastName : null
      this.user.city = this.USER.attributes.city !== null ? this.USER.attributes.city : null
      this.user.country = {
        value: this.createArrFromObject(this.COUNTRIES).filter(option => this.USER.attributes.country === option.value)[0],
        options: this.createArrFromObject(this.COUNTRIES)
      }
      this.user.gender = {
        value: this.user.gender.options.filter(option => this.USER.attributes.gender === option.value)[0],
        options: [
          {value: 'male', placeholder: 'мужской'},
          {value: 'female', placeholder: 'женский'},
        ]
      }
      this.user.lang = {
        value: this.createArrFromObject(this.LANGUAGES).filter(option => this.USER.attributes.language === option.value)[0],
        options: this.createArrFromObject(this.LANGUAGES)
      }
      this.user.timezone = {
        value: this.createArrFromObject(this.TIMEZONES).filter(option => this.USER.attributes.timezone === option.value)[0],
        options: this.createArrFromObject(this.TIMEZONES)
      }
      this.user.birthday.value = this.USER.attributes.birthdayAt !== false ? this.USER.attributes.birthdayAt : null

      this.allUserTeams = this.USER_TEAMS.concat(this.USER_MANAGED_TEAMS);

      this.user.twitch = this.USER.attributes.twitch !== null ? this.USER.attributes.twitch : null
      this.user.vk = this.USER.attributes.vk !== null ? this.USER.attributes.vk : null
      this.user.psn_id = this.USER.attributes.psn_id !== null ? this.USER.attributes.psn_id : null
    },
    updateUserData() {
      let userData = {
        "firstName": this.user.firstName !== undefined ? this.user.firstName : false,
        "lastName": this.user.lastName !== undefined ? this.user.lastName : false,
        "login": this.user.login !== undefined ? this.user.login : false,
        "gender": this.user.gender.value !== undefined ? this.user.gender.value.value : false,
        "timezone": this.user.timezone.value !== undefined ? this.user.timezone.value.value : false,
        "language": this.user.lang.value !== undefined ? this.user.lang.value.value : false,
        "country": this.user.country.value !== undefined ? this.user.country.value.value : false,
        "birthdayAt": this.user.birthday.formatted_value !== undefined ? this.user.birthday.formatted_value : false,
        'city': this.user.city !== undefined ? this.user.city : null,

        'twitch': this.user.twitch !== undefined ? this.user.twitch : null,
        'vk': this.user.vk !== undefined ? this.user.vk : null,
        'psn_id': this.user.psn_id !== undefined ? this.user.psn_id : null,
        "statistics": []
      }
      this.UPDATE_USER_DATA(userData);
      this.isEdit = false;
    },

    formatDate(date) {
      console.log(date, moment(date[0]).format())
      let newDate = moment(date[0]).format();
      this.user.birthday.formatted_value = newDate;
    }
  },
  created() {
    this.setProfileData();
    this.getBirthdayValue();
  },

}
</script>

<style scoped lang="scss">
.dynamic-input {
  margin-left: -8px;
}

.text_yellow {
  color: var(--tournament-color);
}

.multiselect {
  ::v-deep .multiselect__input {
    margin-bottom: 0;
    padding-left: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    min-height: unset;

    &::placeholder {
      color: white;
      opacity: .5;
    }
  }

  ::v-deep .multiselect__content-wrapper {
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #515151;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #242424;
      border-radius: 1px;
    }
  }

  ::v-deep .multiselect__option {
    padding-top: 5px;
    padding-bottom: 5px;

    span {
      &:first-letter {
        text-transform: capitalize;
      }
    }
  }
}
</style>
